import { instanceAxios, instanceAxiosPrivate } from "../../axios"
import { changeLogged, resetState, setContacs, setIsUserIdentified, setLoading, setPerfil, setToken,  setUsersGeneral, updatePerfil } from "./userSlice"
import { toast } from "react-toastify";
import { resetMenu } from "../navigation/navigationSlice";

export const loggedUsers = (users = {}) => {
  return async (dispatch) => {
      const { data } = await instanceAxios.post('/v1/login', {
        ...users
      })
      // eslint-disable-next-line
      const { isSuppliers = false , isUserIdentified = false , message = '', user_id = 0 } = data;
      // Asigna de manera General.
      dispatch(setUsersGeneral(data))
      toast.success(message)      
  }
}

export const validateToken = () => async (dispatch) => {
  try {
    const xhr = new instanceAxiosPrivate()
    const data = await xhr.services.get('v1/renewToken')    
    const { token = '' } = data.data;    
    xhr.setToken(token);
    dispatch(setToken(token));  
  } catch ({response}) {     
      const { mns = 'Inconsistencia en los datos, ingrese nuevamente' } = response?.data;
      toast.error(mns);           
      dispatch(logout());
    }    
}

export const logout = () => async (dispatch) => {  
  const xhr = new instanceAxiosPrivate()
  xhr.removeToken();
  dispatch(setToken(''))
  dispatch(resetMenu())
  dispatch(resetState())
  window.location.replace('/');
}

export const allContacts = () => async (dispatch) => {
  const xhr = new instanceAxiosPrivate()
  const { data } = await xhr.services.get('v1/users')
  dispatch(setContacs(data))
}

export const infoUserAuthenticated = () => async (dispatch) => {
  try {
    const xhr = new instanceAxiosPrivate()
    const { data } = await xhr.services.get('v1/perfil')
    dispatch(setPerfil(data));    
  } catch ({response}) {      
    const { mns = 'Inconsistencia en los datos' } = response?.data;
    toast.error(mns);       
    dispatch(logout());
  }
}

export const validateSignInUsers = (users = {}) => {
  return async (dispatch) => {
      
      const { data } = await instanceAxios.post('/v1/validateLoginUserslG', {
        ...users
      })
      
      // El usuario Existe Se solicita Otp + Password.

      // assign Token the Store.
      localStorage.setItem('token',data.token)
      dispatch(setToken(data.token))
      // logged ok
      dispatch(changeLogged(true))
  }
}

export const validateSignSuppliers = (login = {}) => {
  return async (dispatch) => {
      const { data } = await instanceAxios.post('/v1/validateLoginSuppliers', {
        ...login
      })
      const { token = ''} = data
      const instAxiosPrivate = new instanceAxiosPrivate()
      instAxiosPrivate.setToken(token)
      dispatch(setToken(token))
      dispatch(changeLogged(true))
      dispatch(setIsUserIdentified(true))
      
  }
}

export const getPerfil = () => {
  return async (dispatch, getState) => {
    
    const { perfil } = getState().user;
    try {
      const xhr = new instanceAxiosPrivate();
      const { data } = await xhr.services.get('/v1/user_perfil', {
        params: {
            user_id: perfil.user_id
        }
      })
      if(data){
        dispatch(setPerfil({...perfil, ...data}));    
      }
      // eslint-disable-next-line
    } catch ({response}) {      
      const { mns = 'Inconsistencia en los datos' } = response?.data;
      toast.error(mns);       
    }
  }
}

export const updatePhoto = (e) => {
  return async (dispatch, getState) => {

    const { perfil } = getState().user;

    const xhr = new instanceAxiosPrivate();
    const file = xhr.getItemGeneral('photo');
    
    const formD = new FormData()
    formD.append('files_drive', file);
    formD.append('parents', '15IOq9L3XCVX45G2-A6OMBIpcpP7UFydv');
    formD.append('suppliers', perfil.identification);
    formD.append('type', perfil.user_id);

    let newperfil;
    const { data } = await xhr.services.post(`/v1/managerFilesEvidencias`, formD );
    if(data){
      newperfil = {
        ...perfil,
        photo: data.data.idFoldertType
      }
      
      dispatch(updatePerfil(newperfil))
    }
    return data;
  }
}

export const updateUserData = (info = {}) => {
  return async (dispatch, getState) => {
    dispatch(setLoading(true))
    try {
      await dispatch(updatePhoto());
    } catch (error) {
      toast.error(error);
      dispatch(setLoading(false))
      return
    }

    try {
      await dispatch(sendUpdPerfil())
    } catch (error) {
      toast.error(error);
      dispatch(setLoading(false))
      return
    }
  }
}

export const sendUpdPerfil = () => {
  return async (dispatch, getState) => {
    const { perfil } = getState().user;

    const xhr = new instanceAxiosPrivate();
    const { data } = await xhr.services.put(
      `/v1/upd_user_perfil`, perfil, 
      {
        headers: { 'Content-type': 'application/json' }
      }
    );

    if(data.msn) {
      await dispatch(getPerfil())
      dispatch(setLoading(false))
      toast.success(data.msn);
    }
  }
}

export const resendOtp = (user) => {
  return async (dispatch) => {
    try {
      const { data } = await instanceAxios.post('/v1/resendotp', {
        username: user
      })
      toast.success(data.mns)
    } catch (error) {     
      const { mns = 'Inconsistencia en los datos, ingrese nuevamente' } = error?.response?.data;
      toast.error(mns, { theme: "colored" });        
    } 
  }
}