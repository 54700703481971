import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from "react-hook-form";
import Select from "react-select"
//
import { changeDataEvidences,  setCargueDrive } from '../../../slices/marketing/marketingSlice';
import { getPoSuppliers, getdrivecargue, getfsworkflowactall, getmktapprovalfsworkflow } from '../../../slices/marketing/marketingThunks';
import { CardEvidence } from '../../Cards/CardEvidence';
import LoadingPortal from '../../loadings/LoadingPortal';
import Modal from '../../modal/Modal';

const Evidences = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedAprobador, setSelectedAprobador] = useState([]);

  const dispatch = useDispatch()
  const {  identification = 0, employee_code = 0, user_id = 0, last_name = 0,  first_name = 0}   = useSelector((state) => state.user.perfil)
  const { loading, EvidencesApi, CargueDrive, fsworkflowactall, aprobadores } = useSelector((state) => state.marketing)
  
  const {
    control,
    reset,
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm();

  
  let nit = identification
  let supplier_code = employee_code
  let user_reg = user_id
  let name = first_name + last_name

  const getPo = async (nit) => {
    try{
      dispatch(getPoSuppliers(nit))
    } catch (err) {
      throw new Error(err.message || err.stack || 'Error no identificado' )
    }
  } 

  const getCargueevidencias = async (value) => {
    setIsOpen(true);
    let ctvo = value.ctvo
    let tipo = value.tipo
    dispatch(changeDataEvidences({ ctvo, tipo }))
    dispatch(setCargueDrive(value)) 
  }

  const modalHide = () => {
    setIsOpen(false);
    reset({
      categoria: null,
      file : null,
      obs : null,
      aprobador : null,
    })
    setSelectedAprobador([]);
  }

  const onSubmited = async (data) => {
    try {
      data = {
        parents: '1XL0d7WoUXeOOPyBROhAB1AdluJ-CRPh5', //
        user_id: user_reg,
        suppliers: nit,
        type: CargueDrive.tipo + ' - ' + CargueDrive.ctvo,
        ...data
      }
      let obs = data.obs
  
      if(obs){
        dispatch(getdrivecargue(data, CargueDrive.tipo, CargueDrive.ctvo, obs, name, CargueDrive.policy, CargueDrive.adj_ppto, CargueDrive.cod_ppto))
      }
      setIsOpen(loading);
      reset({
        categoria: null,
        file : null,
        obs : null,
        aprobador : null,
      })
    } catch( err ) {
      throw new Error(err.message || err.stack || 'Error no identificado' )
    } 
  }

  useEffect(() => {
    getPo(nit)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[nit])

  useEffect(() => {
    dispatch(changeDataEvidences ( { nit, supplier_code, user_reg, name} ))
  },[nit, supplier_code, user_reg, name, dispatch])

  useEffect(() => {
    dispatch(getfsworkflowactall ())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
  },[selectedAprobador])

  return (
    <>
      { loading ? (<LoadingPortal isOpen={loading} />): ''}
      <Modal 
        handleClose={modalHide} 
        isOpen={isOpen} 
        header='CARGUE EVIDENCIAS' 
        invoiceNum={' '} 
      >       
        <form className='row col-12 col-md-12' onSubmit={handleSubmit(onSubmited)}>
          <div className='col-12 col-md-12'>
            <div className="form-group">
              <label>Cargue de documentos</label>
              <input 
                type="file"  
                className='form-control'  
                placeholder='Numero factura'
                multiple
                {...register("file", {required: 'Cargar Documentos'})}
                id="file"
              />
              <p>{errors.file?.message}</p>
            </div>
          </div>
          <div className='col-12 col-md-12'>
            <div className="form-group">
              <label>Observación</label>
              <textarea 
                className='form-control'  
                placeholder='Agregar Observación'
                {...register("obs", {required: 'Agregar Una Observacion'})}
                id="obs"
              />
              <p>{errors.obs?.message}</p>
            </div>
          </div>
          <div className='col-12 col-md-12'>
            <div className="form-group">
              <label>Seleccione Línea de aprobación</label>
              <Controller
                control={control}
                name="categoria"
                rules={{ required: true }}
                render={({ field: { onChange, value, name, ref } }) => {
                  const currentSelection =
                    fsworkflowactall && fsworkflowactall.find((c) => c.value === value);
                  return (
                    <>
                      {/* Primer Selector */}
                      <Select
                        label=""
                        instanceId="selectbox"
                        ref={ref}
                        value={currentSelection}
                        name={name}
                        options={fsworkflowactall}
                        className="react-select-container"
                        onChange={(selected) => {
                          onChange(selected.value);
                          setSelectedAprobador([]);
                          setValue("aprobador", null, { shouldValidate: true }); 
                          dispatch(getmktapprovalfsworkflow(selected.value));
                          
                        }}
                        placeholder="Selecciona una Categoría"
                      />
                    </>
                  );
                }}
              />
              {errors.categoria && (<p>Selecciona una categoria</p>)}
              <Controller
                control={control}
                name="aprobador"
                rules={{ required: true }}
                render={({ field: { onChange, value, name, ref } }) => {

                    return (
                      <>
                      <Select
                          label=""
                          instanceId="selectbox2"
                          ref={ref}
                          options={aprobadores}
                          className="react-select-container mt-3"
                          name={name}
                          placeholder="Selecciona un aprobador"
                          //isDisabled={aprobadores.length === 0} 
                          value={selectedAprobador}
                          onChange={(selected) => {
                            onChange(selected.value);
                            //dispatch(setaprobadores([]))
                            setSelectedAprobador(selected);
                          }}
                        />
                      </>
                    )
                  } 
                }
              />
              {errors.aprobador && (<p>Selecciona un aprobador</p>)}
            </div>
          </div>
          <div className='col-12 d-flex justify-content-center'>
            <button 
              type="submit" 
              className='btn btn-danger btn-block font-weight-bold mt-2 w-25'>
              Enviar
            </button>
          </div>
        </form>
      </Modal>
      <div className='row'>
        <div className='col-12'>
          <div className="row my-2">
            <div className="col-sm-6">
              <h1 className="m-0">Marketing Approval Request</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">Marketing</li>
                <li className="breadcrumb-item active">Evidencias</li>
              </ol>
            </div>
          </div>
          <div className='mt-2'>              
          </div>
          <hr />
          <div className='mt-4 px-2' >  
            <div className="row">
            {
              EvidencesApi.length > 0 
              ? EvidencesApi?.map((r, index) => (
                  <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4" key={index}>
                    <CardEvidence item={r} getcargueevidencia={getCargueevidencias}/>
                  </div>
                )
              ) : (
                <p>No hay datos</p>
              )
            }
            </div>   
          </div>
        </div>
      </div>
    </>
  )
}

export default Evidences;