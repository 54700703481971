import { instanceAxiosPrivate } from "../../axios";
import { changeDataEvidences, setEvidences, setLoading, setfsworkflowactall,setaprobadores  } from "./marketingSlice";
import { toast } from "react-toastify";

export const getPoSuppliers = (nit) => {
  return async (dispatch, getState ) => {
    dispatch(setLoading(true));
    const axiosp = new instanceAxiosPrivate()
    const { data } = await axiosp.services.get(
      "/v1/mkt/marketingsuppliers",
      {
        params: { 
          'nit': nit
        }
      }
    )
    dispatch(setEvidences(data));
    dispatch(setLoading(false));
  }
}

export const getdrivecargue = (cuerpo, tipo, ctvo, obs, name, policy, adj_ppto, cod_ppto) => {

  return async ( dispatch, getState ) => {     
    const axiosp = new instanceAxiosPrivate()    
    const formData = new FormData(); 
    // Agregar archivos al formData
    for (let i = 0; i < cuerpo.file.length; i++) {
      formData.append('file[]', cuerpo.file[i]); // 'file[]' si se envían varios archivos
    }

    // Agregar los demás campos
    formData.append('obs', cuerpo.obs);
    formData.append('parents', cuerpo.parents);
    formData.append('suppliers', cuerpo.suppliers);
    formData.append('type', cuerpo.type);
    
    dispatch(setLoading(true));
    const { data } = await axiosp.services.post( "/v1/managerFilesEvidencias", formData )

    let link_evidence = data.data.webViewLink
    let idFolderSupplier = data.data.idFoldertType
    let idFoldertType = data.data.idFoldertType

    let fecha = new Date()
    const soloFecha = fecha.toISOString().split('T')[0];
    const soloHora = fecha.toTimeString().split(' ')[0];
    let fechacrea = soloFecha + ' ' + soloHora
    let fechamod = soloFecha + ' ' + soloHora
    let stamp = soloFecha + ' ' + soloHora
    let aprobador = cuerpo.aprobador

    let title = 'MKT Evidence DIGITAL [ ' + soloFecha + ' ' + soloHora + ' - ' + tipo + ' - ' + ctvo + ' ]'

    let body = `<style>table.tbl_evidence {border-collapse: collapse; border-color: rgba(0,0,0,.2);} table.tbl_evidence, .tbl_evidence th, .tbl_evidence td { border: 1px solid; border-color: rgba(0,0,0,.2); border-collapse: collapse; } .tbl_evidence th, .tbl_evidence td { padding: 3px 10px; } </style> <table class='tbl_evidence'> <tbody> <tr> <th>Vendor</th> <td>${name}</td> </tr> <tr><th>Tipo</th><td>${tipo}</td> </tr>`;

    if (tipo === 'PO') {
        body += `<tr><th>Documento PO</th><td><a href='/procurement/gencontractformatpdf/tipo/${tipo}/ctvo/${ctvo}/version/0' target='_blank'>${tipo} - ${ctvo}</a></td></tr>`;
    } else if (tipo === 'DL') {
        body += `<tr><th>Documento DL</th><td><a href='/procurement/genpoformatpdf/tipo/${tipo}/ctvo/${ctvo}/download/Y' target='_blank'>${tipo} - ${ctvo}</a></td></tr>`;
    }

    body += `<tr><th>Documento Poliza</th><td>${policy}</td></tr><tr><th>Presupuesto</th><td><a href='${adj_ppto}' target='_blank'>${cod_ppto}</a></td></tr><tr><th>Evidencia</th><td><a href='${link_evidence}' target='_blank'>${link_evidence}</a></td></tr><tr><th>Comentarios</th><td>${obs}</td></tr></tbody></table>`;

    dispatch(changeDataEvidences({ link_evidence, idFolderSupplier, idFoldertType, fechacrea,  fechamod, stamp, title, obs, body, aprobador}))
    dispatch(setLoading(false));
    const state = getState();
    const evidences = state.marketing.evidences; // Accede al estado directamente
    dispatch(getmarketingapproval(evidences))
  }
}

export const getmarketingapproval = (body) => {
  return async (dispatch, getState ) => {
    dispatch(setLoading(true));
    const axiosp = new instanceAxiosPrivate()
    await axiosp.services.get( "/v1/mkt/marketingdrive",
      {
        params: body
      }
    )
    toast.success("registro correcto");
    dispatch(setLoading(false));
  }
}

export const getfsworkflowactall = () => {
  return async (dispatch, getState ) => {
    const axiosp = new instanceAxiosPrivate()
    const { data } = await axiosp.services.get("/v1/mkt/mktfsworkflowact")
    let categoria =  data.map(r => {
      return {
        label: r.name_act,
        value: r.name_act
      }
    });
  dispatch(setfsworkflowactall(categoria));
  }
}

export const getmktapprovalfsworkflow = (categoria) => {
  return async (dispatch, getState ) => {
    const axiosp = new instanceAxiosPrivate()
    const { data } = await axiosp.services.get(
      "/v1/mkt/mktapprovalfsworkflow?name_act=" + categoria
    )
    let aprobadores = data.map((aprobador) => ({
      value: aprobador.user_id,
      label: aprobador.usuario_aprobador,
    }))
    dispatch(setaprobadores(aprobadores));
  }
}