import React from "react"
import { Route, Routes } from "react-router-dom"
import { Login } from "../components/user/Login"
import { Register } from "../components/user/Register"
import { PrivateRoutes } from "./PrivateRoutes"
import { BiRoutes } from "../components/BI/routes/BiRoutes"
import { PublicRoutes } from "./PublicRoutes"
import { UserRoutes } from "../components/user/routes/UserRoutes"
import ErrorBoundary from "../components/ErrorBoundary/ErrorBoundary"
import { PagosRoutes } from "../components/PAGOS/routes/PagosRoutes"
import { MarketingRoutes } from "../components/Marketing/routes/MarketingRoutes"

export const AppRoutes = () => {
  
  return(
    <Routes>
      {/* Rutas Publicas */}
      <Route
        path="/"
        element={
          <PublicRoutes>
            <Login /> 
          </PublicRoutes>
        }
        errorElement={<ErrorBoundary />}
      />
      <Route 
        path="/register"
        element={<Register />}
        errorElement={<ErrorBoundary />}
      />
      {/* Rutas Privadas */}
      <Route path="/*" 
        element={
          <PrivateRoutes> 
            <BiRoutes/>
            <PagosRoutes/>
            <UserRoutes/>
            <MarketingRoutes/>
          </PrivateRoutes>
        }
        errorElement={<ErrorBoundary />}
      />
    </Routes>
  )
}