import { createSlice } from "@reduxjs/toolkit";

export const marketingSlice = createSlice({

    name: 'marketing',
    initialState: {
      evidences: {
        "title": "",
        "body": "",
        "module": "6",
        "submodule": "2",
        "type_action": "A",
        "class": "",
        "action": "",
        "key_vars": "",
        "state": "P",
        "user_reg": "",
        "stamp": "",
        "nit": "",
        "name": "",
        "supplier_code": "",
        "tipo": "",
        "ctvo": "",
        "link_evidence": "",
        "status": "P",
        "obs": "",
        "fechacrea": "",
        "fechamod": "",
        "compras_mkt_evidence_id_anterior": "0",
        "idFolderSupplier": "",
        "idFoldertType": "",
        "aprobador":"",
      },
      loading: false,
      loadingdrive:false,
      EvidencesApi: [],
      CargueDrive: {},
      ValidationDrive: {},
      fsworkflowactall:[],
      aprobadores:[],
    } ,
    reducers: {
        changeDataEvidences: ( state, {payload}) => {
          
          state.evidences = {
            ...state.evidences,
            ...payload
          } 
        },
        setLoading: (state, { payload }) => {
          state.loading = payload;
        },
        setLoadingdrive: (state, { payload }) => {
          state.loadingdrive = payload;
        },
        setEvidences: (state, { payload }) => {
          state.EvidencesApi = payload;
        },
        setCargueDrive: (state, { payload }) => {
          state.CargueDrive = {
            ...state.CargueDrive,
            ...payload
          }
        },
        setValidationDrive: (state, {payload}) => {
          state.ValidationDrive = {
            ...state.ValidationDrive,
            ...payload
          }
        },
        setfsworkflowactall: (state, {payload}) => {
          state.fsworkflowactall = payload 
        },
        setaprobadores: (state, {payload}) => {
          state.aprobadores = payload 
        }
    }
})


export const {
  changeDataEvidences, 
  setLoading,
  setLoadingdrive,
  setEvidences,
  setCargueDrive,
  setfsworkflowactall,
  setaprobadores
} = marketingSlice.actions;