import { configureStore } from '@reduxjs/toolkit';

import   {userSlice, wosSlice, alertsSlice} from '../slices';
import { navigationSlice } from '../slices/navigation/navigationSlice';
import { pagosSlice } from '../slices/pagos/pagosSlice';
import { marketingSlice } from '../slices/marketing/marketingSlice';
import { vendorSlice } from '../slices/vendor/vendorSlice';


export const store = configureStore({
  reducer: {
    bi_wos: wosSlice.reducer,
    user: userSlice.reducer,
    alerts : alertsSlice.reducer,
    navigation: navigationSlice.reducer,
    pagos: pagosSlice.reducer,
    marketing: marketingSlice.reducer,
    form_vendor: vendorSlice.reducer,
  },
})