import React, {useEffect} from 'react';
import ReactDOM from "react-dom";



const Modal = ({children, isOpen, handleClose, header, invoiceNum = null}) => {
    useEffect(() => {
        const closeOnEscapeKey = e => e.key === "Escape" ? handleClose() : null;
        document.body.addEventListener("keydown", closeOnEscapeKey);
        return () => {
            document.body.removeEventListener("keydown", closeOnEscapeKey);
        };
        
    }, [handleClose]);

    let  myModalContainer;

    if (!isOpen) return null;

    if (window !== "undefined") {
        myModalContainer = document.querySelector("#modal");    
    }
    
  return (
    isOpen &&
    ReactDOM.createPortal(
        <div className="modal fade show"  id="modal" style={{paddingRight: '17px', display: 'block', backgroundColor: "rgba(0, 0, 0, 0.7)", backdropFilter: 'blur(5px)'}} aria-modal="true" data-backdrop="static" role='dialog'>
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        {   invoiceNum
                            ?   <h4 className="modal-title font-weight-bold">
                                    {header}&nbsp;&nbsp;
                                    <span>
                                        {invoiceNum.split(' ')[0]} 
                                        <b className='text-danger'>{invoiceNum.split(' ')[1]}</b>
                                    </span>
                                </h4>
                            : <h4 className="modal-title font-weight-bold">{header}</h4>
                        }
                        <button 
                            type="button" 
                            onClick={handleClose}  
                            className="close" 
                            data-dismiss="modal" 
                            aria-label="Close"
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {children}
                    </div>                
                </div>
            </div>
        </div>,
        myModalContainer
    )
  );
}

export default Modal