import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Evidences from '../pages/Evidences'


export const MarketingRoutes = () => {
  return (
    <Routes>
      <Route path="marketing" >
        <Route path='evidences' element={<Evidences/>} />
      </Route>    
    </Routes>
  )
}
