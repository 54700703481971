import React, {useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getPerfil, infoUserAuthenticated } from "../../slices";

export const UserPanel = ({ link })=> {
  const dispatch = useDispatch()
  const { perfil } = useSelector( state => state.user)
  const [photo, setPhoto] = useState('')

  let imageAlt = 'User Image';

  useEffect(() => {
    dispatch(infoUserAuthenticated())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    if(perfil.user_id){
      dispatch(getPerfil())
    }
  },[perfil.user_id,   dispatch])

  useEffect(() => {
    if(perfil.photo !== '' && perfil.photo !== null){
      setPhoto(`https://lh3.googleusercontent.com/u/0/d/${perfil.photo}=w1920-h974-iv1`)
    } else  {
      setPhoto(`${process.env.REACT_APP_HOST}/dist/img/Lg-logo.png`)
    }
  }, [perfil])

  

  return (
    <div 
      className="user-panel mt-3 pb-3 mb-3 d-flex"
    >
      <div className="image">
        <img 
          src={photo} 
          className="img-circle elevation-2" 
          alt={imageAlt} 
          onError={event => {
            event.target.src = `${process.env.REACT_APP_HOST}/dist/img/Lg-logo.png`
            event.onerror = null
          }}
        />
      </div>
      <div className="info">
        <a href={link}
          className="d-block text-decoration-none"
        >
          {perfil.first_name}
        </a>
      </div>
    </div>
  )
}