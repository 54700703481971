import React, { useEffect,useState } from "react"
import { Navigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { Content } from "../components/content/Content"
import { validateToken } from "../slices"

export const PrivateRoutes = ({ children }) => {
  // validar Login
  const localtoken = localStorage.getItem('token');
  // eslint-disable-next-line
  const [token, setToken] = useState(localtoken)
  const dispatch = useDispatch()

  useEffect( () => {
    setInterval( async () => {
      if (localtoken !== null && token !== null && document.visibilityState === 'visible') {
        dispatch(validateToken());
      }
    }, 600000);//Ejecucion cada 10 minutos 
    
  }, [localtoken, token, dispatch])

  return !!token 
      ? (<Content> {children} </Content>)
      : <Navigate to="/" />
}