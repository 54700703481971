import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loggedUsers, resendOtp, validateSignInUsers, validateSignSuppliers } from '../../slices';
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Modal from '../modal/Modal';

const emailRegex = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}$/i)

const schema = yup.object().shape({
  usernameform: yup.string().trim().required("Required").email("Invalid email").matches(emailRegex,"Invalid email"),
})
export const Login = ({ onLogin, preventDefault }) => {
  const { 
    logged = false, 
    isSuppliers, 
    isUserIdentified, 
    user_id, 
    username:usernameLogin = '',
    first_name, 
    photo
  } = useSelector(state => state.user)
  const [username, setUsername] = useState(usernameLogin) //User: e.moraleszambrano Suppliers: 1004774762@lge.com
  const [password, setPassword] = useState('')
  const [otp, setOtp] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [path] = useState(localStorage.getItem('lastPath') || '/home')
  const dispatch = useDispatch()
  const navigate = useNavigate()


  const SingIn = async (event) => {
    try {
      await dispatch(
        loggedUsers({
          username
        })
      )
      navigate(`${path}`,
        {
          replace: true
        }
      )

    } catch ({ response }) {
      const { mns = 'Error no Identificado' } = response?.data
      toastr.error(mns) // eslint-disable-line
    }
  }
  //login users LG
  const validateSignIn = async () => {
    try {
      dispatch(
        validateSignInUsers({
          username,
          password,
          otp
        })
      )
      navigate(`${path}`,
        {
          replace: true
        }
      )
    } catch ({ response }) {
      const { mns = 'Error no Identificado' } = response?.data
      toastr.error(mns) // eslint-disable-line
    }
  }
  // login supplier
  const SingInSuppliers = async () => {
    try {
      await dispatch(
        validateSignSuppliers({
          user_id,
          otp
        })
      )
      navigate(`${path}`,
        {
          replace: true
        }
      )
    } catch ({ response }) {
      const { message = 'Error no Identificado' } = response?.data
      toastr.error(message) // eslint-disable-line
    }
  }

  // register
  const registerLink = () => {
    navigate('/register')
  }

  //otp
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    reValidateMode: 'onSubmit'
  });

  const onSubmitOtp = async (data) => {
    try{
      dispatch(resendOtp(data.usernameform));
      setIsOpen(false);
      reset({
        usernameform: ''
      });
    } catch ({ response }) {
      const { mns = 'Error no Identificado' } = response?.data
      toastr.error(mns) // eslint-disable-line
    }
  }

  const modalShow = () => {
    setIsOpen(true);
  }
  const modalHide = () => {
    setIsOpen(false);
    reset({
      usernameform: ''
    });
  }

  useEffect(() => {
  }, [username])
  useEffect(() => {
  }, [password])
  useEffect(() => {
  }, [otp])
  useEffect(() => {
  }, [logged])

  return (
    <>
      <Modal 
        isOpen={isOpen} 
        handleClose={modalHide}
        header='Resend OTP' 
      >
        <form
          className='container-fluid mx-0 px-0' 
          onSubmit={handleSubmit(onSubmitOtp)}
        >
          <div className="row">
            <div className="col-12 col-sm-8 col-md-8">
              <div className="input-group mb-2">
                <input
                  {...register('usernameform')}
                  type="email"
                  className="form-control"
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className={"fas fa-envelope"}></span>
                  </div>
                </div>
              </div>
              {errors && (<p className='text-red'>{errors?.usernameform?.message}</p>)}
            </div>
            <div className="col-12 col-sm-4 col-md-4">
              <button 
                type="submit" 
                className="btn btn-danger btn-block"
                onClick={()=>handleSubmit(onSubmitOtp)}
              >
                Resend OTP
              </button>
            </div>
          </div>
        </form>
      </Modal>
    
      <div className="hold-transition login-page">
        <div className="login-box">
          <div className="login-logo">
            <b className='textred font-weight-bold'>G2</b> LG
          </div>
          <div className="card">
            <div className="card-body login-card-body">
              <div className="text-center">
              {
                isSuppliers
                  ? <img 
                    src={`https://lh3.googleusercontent.com/u/0/d/${photo}=w1920-h974-iv1`} 
                    className='profile-user-img img-fluid img-circle' 
                    onError={event => {
                      event.target.src = `${process.env.REACT_APP_HOST}/dist/img/Lg-logo.png`
                      event.onerror = null
                    }}
                    alt="User" 
                  />
                  : ''
              }
                
              </div>
              <p className="login-box-msg">{ first_name || `Sign in to start your session`}</p>
              <div className="input-group mb-2 px-1">
                <input
                  name="username"
                  value={username}
                  type="text"
                  className="form-control"
                  placeholder="User"
                  onChange={(e) => setUsername(e.target.value)}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className={isUserIdentified ? "fas fa-user" : "fas fa-envelope"}></span>
                  </div>
                </div>
              </div>

              <div className="input-group mb-2 px-1">
                {
                  isUserIdentified === !isSuppliers && (
                    <div className="input-group mb-2">
                      <input
                        name="password"
                        type="password"
                        value={password}
                        className="form-control"
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <div className="input-group-append">
                        <div className="input-group-text">
                          <span className="fas fa-lock"></span>
                        </div>
                      </div>
                    </div>
                  )
                }
                {
                  (isUserIdentified) &&
                  <>
                    <input
                      name='otp'
                      type="password"
                      className="form-control"
                      placeholder="OTP"
                      onChange={(e) => setOtp(e.target.value)}
                    />
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-lock"></span>
                      </div>
                    </div>
                  </>
                }
              </div>

              <div className="row px-1">
                <div className="col-12">
                  {
                    isSuppliers
                      ? <button type="button" className="btn btn-block btn-outline-danger btn-lg px-2" onClick={SingInSuppliers}>Login Proveedor</button>
                      : <button type="button" className="btn btn-block btn-danger btn-lg px-2" onClick={isUserIdentified ? validateSignIn : SingIn}>
                      Sign In </button>
                  }
                </div>
              </div>   

              <div className="row">
                {
                  isSuppliers
                  ? (
                      <div className="col-12 text-left mt-4 px-3">
                        <button 
                          type='button'
                          className="text-left text-secondary btn btn-link text-decoration-none p-0"  
                          onClick={modalShow}
                        >Resend OTP</button>
                      </div>
                  ) : ''
                }   
                <div className="col-12 text-left mt-2 px-3">
                  <span className="text-left text-secondary" onClick={registerLink} role="button" tabIndex="0">Register a new vendor</span>
                </div>   
              </div>         
            </div>
          </div>        
        </div>
      </div>
    </>
  )
}
