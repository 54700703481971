import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "users",
  initialState: {
    logged: false,
    token: '',
    contacs: [],
    perfil: {},
    isSuppliers: false,
    isUserIdentified: false,
    user_id: 0,
    username: '',
    employee_code: '',
    first_name: '', 
    loading: false, 
    photo: ''
  },
  reducers: {
    changeLogged: ( state, {payload}) => {
      state.logged = payload
    },
    setToken: ( state, {payload}) => {
      state.token = payload
    },
    setContacs: ( state, {payload}) => {
      state.contacs = payload
    },
    setPerfil: ( state, {payload}) => {
      state.perfil = payload
    },
    setIsSuppliers: ( state, {payload}) => {
      state.isSuppliers = payload
    },
    setIsUserIdentified: ( state, {payload}) => {
      state.isUserIdentified = payload
    },
    setUserid: ( state, {payload}) => {
      state.user_id = payload
    },
    setUsersGeneral: (state, {payload}) => {
      Object.assign(state, payload)
    }, 
    resetState: (state) => {
      Object.assign(state, state)
    },
    updatePerfil: (state, { payload }) => {
      state = Object.assign(state.perfil, payload);
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
  }
})

// Action creators are generated for each case reducer function
export const {
  changeLogged,
  setToken,
  setContacs,
  setPerfil,
  setIsSuppliers,
  setIsUserIdentified,
  setUserid,
  setUsersGeneral,
  resetState,
  updatePerfil,
  setLoading
} = userSlice.actions
