import React from 'react';
import { toast } from "react-toastify";

export const CardEvidence = (props) => {
  const values = props.item; 

  let validation_card = values.status === 'P' ? 'card card-success card-outline' : 'card card-danger card-outline'
  let validation_status = values.status === 'P' ? 'fint-weight-bold text-green text-break' : 'fint-weight-bold text-red text-break'

  return (
    <div 
      className = { validation_card }
      onClick={() => {
          if (values.cases_icon === 0 ) {
            toast.info('Esta factura no cuenta con datos de pago');
          } 
          if(props.hasOwnProperty('getcargueevidencia') && values.status !== 'P') {
            props.getcargueevidencia(values)
          } 
      } 
    } 
      style={{ cursor: 'pointer' }}
    >
      <div className="card-body">
        <div className='row px-1'>
          <div className='col-12 col-md-12 col-lg-6 col-xl-12'>
            <div className="row">
              <h5  className="card-title font-weight-bold">SERVICIO:   <br/> 
              <span className = { validation_status } >
                {values.contract_name}
              </span></h5> 
            </div>
          </div>

          <div className='col-12 col-md-12 col-lg-6 col-xl-12'>
                         
            <h5  className="card-title font-weight-bold"># PO:   <br/> 
              <span className = { validation_status }>
                {values.tipo} -- {values.ctvo}
              </span>
            </h5>
              
          </div> 

          <div className='col-12 col-md-12 col-lg-6 col-xl-12'>
            <h5 className="card-title font-weight-bold"> ESTADO: <br/>
              <span className = { validation_status } >
              {
                values.status === 'P' ? 'Pendiente de aprobación' : 'A espera de inicio de flujo' 
              }
              </span>
            </h5>
          </div>

        </div>
      </div>
    </div>
  );
};
  